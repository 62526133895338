import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import ProductRichSnippet from 'components/common/rich-snippets/product/Product';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CallToAction from 'components/directus/call-to-action/CallToAction';
import FaqInfoPanel from 'components/directus/faq-info-panel/FaqInfoPanel';
import OurAddress from 'components/directus/our-address/OurAddress';
import PageTitle from 'components/directus/page-title/PageTitle';
import PurchaseAnAddress from 'components/directus/purchase-an-address/PurchaseAnAddress';
import ServiceContent from 'components/directus/service-content/ServiceContent';
import Testimonials from 'components/directus/testimonials/Testimonials';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'service-address';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

  return (
    <>
      <Helmet titleTemplate="">
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>

      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} buyService />
        </Section>
        <Section>
          <ServiceContent columns={2} slug={slug} />
        </Section>
        <Section>
          <OurAddress slug={slug} />
        </Section>
        <Section>
          <PurchaseAnAddress slug={slug} columns={2} title="Other ways to buy a service address" />
        </Section>
        <Section>
          <FaqInfoPanel slug={slug} />
        </Section>
        <Section>
          <Testimonials />
        </Section>
        <Section>
          <CallToAction slug="company-registration" />
        </Section>
      </Wrapper>
      <ProductRichSnippet slug={slug} />
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "service-address" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
